<template>
  <div section-title :class="{ black }">
    <h2 v-prx:intro="{ r: [0.5, 2.5], ty: [-50, 0], o: [0, 1] }">
      {{ title }}
      <span class="ko" v-if="titleKo">{{ titleKo }}</span>
    </h2>
    <button class="btn dark-grey more" v-if="more" @click="buttonAction" :disabled="disable">더보기</button>
  </div>
</template>

<script>

import gtag from 'shared/mixins/gtag';

export default {
  name: 'SectionTitle',
  mixins: [gtag],
  props: {
    black: Boolean,
    title: String,
    titleKo: String,
    search: Boolean,
    more: Boolean,
    sm: Boolean,
    tab: Boolean,
    value: { type: Object, default: () => ({}) },
    items: { type: Array, default: () => [] },
    disable: Boolean,
  },
  computed: {
    size() {
      return this.$store.state.browser.matchedMedia;
    },
  },
  data() {
    return {};
  },
  methods: {
    init() {
    },
    buttonAction() {
      this.trackEvent('riot_LoL_Aleague_more', `click_${this.title}`);
      this.$emit('button-action');
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[section-title] { .c(white); .rel; .clear; .tl;
  &.black {.c(black);
    .ko {.c(black); .ls(-7%);}
  }
  .btn.more {.abs; .rt(0, -8); .wh(70, 32); .p(0); .fs(14); .br(33); .c(rgba(255, 255, 255, .8));
    &:disabled {cursor: default; .o(.5);}
  }
  > h2 { .font-russo; .fs(22); .regular; .vam; .rel; .pl(10); .ml(-10);
    .ko {.font-g; .ib; .fs(16); .vam; .regular; .ml(8); .mb(4); .c(rgba(255, 255, 255, .8)); }
    .line {.hide;}
  }
  // @media(@tp-up) {
  //   .btn.more{.t(0); .wh(75, 38); .p(0); .fs(15); .br(33);}
  //   >h2{.fs(30);
  //     .ko{.fs(19); .mb(6); .ml(16);}
  //   }
  // }
  @media (@tl-up) {
    .btn.more {.t(0); .wh(85, 45); .fs(16); .br(33);}
    > h2 {.fs(40);
      .ko {.fs(26); .mb(6); .ml(16);}
    }
    .search {.tr; .w(248);}
  }
  @media (@ds-up) {
    .btn.more {.wh(85, 45); .fs(16); .br(33);}
    > h2 {.fs(40);
      .line {.block; .h(28); .abs; left: calc(-100%); .t(10); .w(100%); z-index: 2; overflow: hidden;
        em {.block; .bg('@{attach}/title-line-w.png'); .wh(440, 100%); .abs; .rt(0, 0);}
      }
    }
    &.black {
      > h2 {
        .ko {}
        .line {
          em { .bg('@{attach}/title-line.png'); .w(480); }
        }
      }
    }
  }
}
</style>
