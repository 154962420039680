<template>
  <div>
    <PSection tft-result>
      <div class="inner-holder">
        <SectionTitle title-ko="대회 결과" black sm />
        <div class="empty" v-if="!isResult">
          대회 결과는 스테이지 종료 후 업데이트 됩니다.
        </div>
        <div v-else class="result">
          <WeeklyRankResult />
          <RankResult />
          <div class="chars">
            <figure class="char1">
              <img src="/img/pages/tftWeeklyCup/leaderboard/cha_01.png" alt="">
            </figure>
            <figure class="char2">
              <img src="/img/pages/tftWeeklyCup/leaderboard/cha_02.png" alt="">
            </figure>
          </div>
        </div>
      </div>
    </PSection>
  </div>
</template>

<script>
import SectionTitle from '@/views/components/common/SectionTitle.vue';
import meta from '@/data/meta';
import RankResult from '@/views/components/leaderboard/tftWeeklyCup/RankResult.vue';
import WeeklyRankResult from '@/views/components/leaderboard/tftWeeklyCup/WeeklyRankResult.vue';

export default {
  name: 'tftResult',
  components: { SectionTitle, WeeklyRankResult, RankResult },
  data() {
    return {
      isResult: true,
    };
  },
  metaInfo() {
    return meta[this.$route.params.id][this.$route.name];
  },
};

</script>

<style lang="less">
@import '~@/less/proj.less';

@keyframes dodongsilTft {
  0% { transform+_: translateY(-10px) scale(1); }
  40% { transform+_: translateY(10px) scale(.98); }
  100% { transform+_: translateY(-10px) scale(1); }
}

[tft-result] {
  .inner-holder { .p(40, 20, 60);
    [section-title] {
      .ko {.fs(20, 20); .bold; .font-g; .ls(-1); .mb(20); }
    }
    .empty { .wh(100%, 600); .bgc(#ebebf0); .br(4); .items-center; .flex-middle; .c(rgba(0, 0, 0, .8));
      &:before { .cnt; .wh(60, 52); .contain('@{tft-leaderboard}/amateur.svg'); .mb(20);}
    }
    .result { .cover('@{tft-leaderboard}/BG.jpg'); .wh(100%); .bg-c; .rel; overflow: hidden;
      .chars { .hide; .abs; .lt(0, 0); .wh(100%);
        .char1 > img { .abs; .z(2); .rt(-30, 232); animation: dodongsilTft 2.5s 0.5s ease-in-out infinite;}
        .char2 > img { .abs; .z(2); .lt(-98, 950); animation: dodongsilTft 2.5s 0.5s ease-in-out infinite;}
      }
    }
  }
  @media (@tp-up) {
    .w(710); .mh-c;
  }

  @media (@tl-up) {
    .w(966);
    .inner-holder { .p(60, 0);
      [section-title] {
        .ko { .fs(32, 32); .ml(0); .mb(40);}
      }
      .result {
        .chars { .block;
        }
      }
    }
  }
  @media (@ds-up) {
    .w(1200);
    .inner-holder { .p(60, 0, 120);
      .result {
        .chars {
          .char1 > img { .abs; .z(2); .rt(75, 250); animation: dodongsilTft 2.5s 0.5s ease-in-out infinite;}
          .char2 > img { .abs; .z(2); .lt(22, 950); animation: dodongsilTft 2.5s 0.5s ease-in-out infinite;}
        }
      }
    }
  }

}

</style>
