<template>
  <div weekly-rank-result>
    <div class="contents-container">
      <div class="contents-holder">
        <h4 class="title">주차별 순위 결과</h4>
        <div class="inner-box">
          <ToggleTab :tabs="tabList" :value="week" @input="getRankInfo" />
          <TftRankTable weekly :info="weeklyRank" />
        </div>
      </div>
    </div>
    <div class="divider" />
  </div>
</template>

<script>
import ToggleTab from '@/views/components/common/tab/ToggleTab.vue';
import TftRankTable from '@/views/components/leaderboard/tftWeeklyCup/TftRankTable.vue';

export default {
  name: 'WeeklyRankResult',
  components: { TftRankTable, ToggleTab },
  data() {
    return {
      tabList: [{ label: '1주차', disabled: false }, { label: '2주차', disabled: false }, { label: '3주차', disabled: false }, { label: '4주차', disabled: false }, { label: '5주차', disabled: false }],
      weeklyData: {
        1: [
          { rank: 1, player: '이것만하고할게', weeklyPrice: 50, weeklyPoint: 10 },
          { rank: 2, player: '서강대영미어문', weeklyPrice: 40, weeklyPoint: 8 },
          { rank: 3, player: 'Kanata Ch', weeklyPrice: 20, weeklyPoint: 6 },
          { rank: 4, player: '광어콩', weeklyPrice: 10, weeklyPoint: 5 },
        ],
        2: [
          { rank: 1, player: '볼빵빵 테리어몬', weeklyPrice: 50, weeklyPoint: 10 },
          { rank: 2, player: '구깨룩3', weeklyPrice: 40, weeklyPoint: 8 },
          { rank: 3, player: '엄븍동의 자전차', weeklyPrice: 20, weeklyPoint: 6 },
          { rank: 4, player: 'SanChess', weeklyPrice: 10, weeklyPoint: 5 },
        ],
        3: [
          { rank: 1, player: '카캇 S2', weeklyPrice: 50, weeklyPoint: 10 },
          { rank: 2, player: '강선종', weeklyPrice: 40, weeklyPoint: 8 },
          { rank: 3, player: '바니 보고 배움', weeklyPrice: 20, weeklyPoint: 6 },
          { rank: 4, player: '신영이와 윌럼프', weeklyPrice: 10, weeklyPoint: 5 },
        ],
        4: [
          { rank: 1, player: 'Luzesky', weeklyPrice: 50, weeklyPoint: 10 },
          { rank: 2, player: 'Teukhan', weeklyPrice: 40, weeklyPoint: 8 },
          { rank: 3, player: '평소엔', weeklyPrice: 20, weeklyPoint: 6 },
          { rank: 4, player: '지팡이주라', weeklyPrice: 10, weeklyPoint: 5 },
        ],
        5: [
          { rank: 1, player: 'uL 코스모', weeklyPrice: 50, weeklyPoint: 10 },
          { rank: 2, player: '파레인', weeklyPrice: 40, weeklyPoint: 8 },
          { rank: 3, player: '이집트신화', weeklyPrice: 20, weeklyPoint: 6 },
          { rank: 4, player: '나찾으면던짐수고', weeklyPrice: 10, weeklyPoint: 5 },
        ],
      },
      week: 4,
    };
  },
  computed: {
    weeklyRank() {
      return {
        label: ['순위', '소환사명', 'WEEKLY 상금', 'WEEKLY 포인트'],
        data: this.weeklyData[this.week + 1],
      };
    },
  },
  methods: {
    getRankInfo(v) {
      this.week = v;
    },
  },
};

</script>

<style lang="less">
@import '~@/less/proj.less';

[weekly-rank-result] { .p(90, 20, 0, 20); .font-g; .tc;
  .contents-container { .rel; .bgc(#263889); .p(4);
    &:before { .cnt; .wh(144, 7); .abs; .z(1); .lb(50%, 4); .t-x(-50%); .bg-c; .cover('@{tft-leaderboard}/table_frame_polygon.svg');}
    .contents-holder { .bgc(#0c1b60); .rel; .p(37, 6, 34);
      &:before { .abs; .cnt; .lt(0, 0); .wh(0, 0); border-style: solid; border-width: 30px 30px 0 0; border-color: #263889 transparent transparent transparent;}
      &:after { .abs; .cnt; .rt(0, 0); .wh(0, 0); border-style: solid; border-width: 0 30px 30px 0; border-color: transparent #263889 transparent transparent;}
      .title { .abs; .lt(50%, -37); .t-x(-50%); .z(1); .mh-c; .fs(18, 47); .ls(-2); .c(white); .wh(280, 47); .bg-c; .bg('@{tft-leaderboard}/title_frame_m.svg');}
      .inner-box { .wh(100%);
        &:before { .abs; .cnt; .lb(0, 0); .wh(0, 0); border-style: solid; border-width: 30px 0 0 30px; border-color: transparent transparent transparent #263889;}
        &:after { .abs; .cnt; .rb(0, 0); .wh(0, 0); border-style: solid; border-width: 0 0 30px 30px; border-color: transparent transparent #263889 transparent;}
        .tab { .mb(10); .-b(#80fc88, 4); .tl;
          > button { .rel; .max-w(63); .z(1); .calc-w(20%, 2px); .h(40); .ml(2); .p(14, 0); .fs(13); .font-g; .c(#fff); .bold; .bgc(#0d6d97);
            &::after { .cnt; .abs; .rt(0, 0); .z(1); .wh(0, 0); .-b(transparent, 12); .-r(#0c1b60, 12); }
            &:disabled { .bgc(#0c3471); cursor: default; .c(rgba(255, 255, 255, 0.16));}
            &:first-child { .ml(0); }
            &.on { .c(#241877); background: linear-gradient(180deg, #e4ff9a 0%, #80fc87 100%); }
          }
        }
      }
    }
  }
  .divider { .bg('@{tft-home}/div_m.svg'); .bg-c; .no-repeat; .wh(272, 8); .mh-c; .mt(24); .mb(58);}
  @media (@tl-up) {
    .contents-container { .max-w(780); .mh-c; .p(6);
      &:before {.lb(50%, 6);}
      .contents-holder { .p(40, 44, 58);
        .title { .t(-42); .wh(396, 53); .bg('@{tft-leaderboard}/title_frame_pc.svg'); .fs(22, 53);}
        .inner-box {
          .tab { .mb(16); .-b(#80fc88, 6);
            > button { .max-w(110); .h(50); .p(17, 0); .fs(18);
              &::after { .-b(transparent, 15); .-r(#0c1b60, 15); }
            }
          }
        }
      }
    }
  }
}

</style>
