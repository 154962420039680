<template>
  <div rank-result>
    <div class="contents-container">
      <div class="contents-holder">
        <h4 class="title">누적 순위 결과</h4>
        <div class="inner-box">
          <p class="detail"><em class="bullet1">*</em>WEEKLY 포인트 동률 시, 최근 라운드의<br /> 높은 순위의 선수가 상위에 위치합니다.<em class="bullet2">*</em></p>
          <TftRankTable :info="accumulateRank" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TftRankTable from '@/views/components/leaderboard/tftWeeklyCup/TftRankTable.vue';

export default {
  name: 'RankResult',
  components: { TftRankTable },
  data() {
    return {
      accumulateRank: {
        title: 'N주차 누적 순위결과',
        sub: 'WEEKLY 포인트 동률 시 최근 라운드의 높은 순위의 선수가 상위에 위치합니다.',
        label: ['순위', '소환사명', 'WEEKLY 포인트'],
        data: [
          { rank: 1, player: 'uL 코스모', weeklyPoint: 10 },
          { rank: 2, player: 'Luzesky', weeklyPoint: 10 },
          { rank: 3, player: '카캇 S2', weeklyPoint: 10 },
          { rank: 4, player: '볼빵빵 테리어몬', weeklyPoint: 10 },
          { rank: 5, player: '이것만하고할게', weeklyPoint: 10 },
          { rank: 6, player: '파레인', weeklyPoint: 8 },
          { rank: 7, player: 'Teukhan', weeklyPoint: 8 },
          { rank: 8, player: '강선종', weeklyPoint: 8 },
          { rank: 9, player: '구깨룩3', weeklyPoint: 8 },
          { rank: 10, player: '서강대영미어문', weeklyPoint: 8 },
          { rank: 11, player: '이집트신화', weeklyPoint: 6 },
          { rank: 12, player: '평소엔', weeklyPoint: 6 },
          { rank: 13, player: '바니 보고 배움', weeklyPoint: 6 },
          { rank: 14, player: '엄븍동의 자전차', weeklyPoint: 6 },
          { rank: 15, player: 'Kanata Ch', weeklyPoint: 6 },
          { rank: 16, player: '나찾으면던짐수고', weeklyPoint: 5 },
          { rank: 17, player: '지팡이주라', weeklyPoint: 5 },
          { rank: 18, player: '신영이와 윌럼프', weeklyPoint: 5 },
          { rank: 19, player: 'SanChess', weeklyPoint: 5 },
          { rank: 20, player: '광어콩', weeklyPoint: 5 },
        ],
      },
    };
  },
};

</script>

<style lang="less">
@import '~@/less/proj.less';

[rank-result] { .p(0, 20, 100, 20); .font-g; .tc;
  .contents-container { .rel; .bgc(#263889); .p(4);
    &:before { .cnt; .wh(144, 7); .abs; .z(1); .lb(50%, 4); .t-x(-50%); .bg-c; .cover('@{tft-leaderboard}/table_frame_polygon.svg');}
    .contents-holder { .bgc(#0c1b60); .rel; .p(33, 6);
      &:before { .abs; .cnt; .lt(0, 0); .wh(0, 0); border-style: solid; border-width: 30px 30px 0 0; border-color: #263889 transparent transparent transparent;}
      &:after { .abs; .cnt; .rt(0, 0); .wh(0, 0); border-style: solid; border-width: 0 30px 30px 0; border-color: transparent #263889 transparent transparent;}
      .title { .abs; .lt(50%, -37); .t-x(-50%); .z(1); .mh-c; .fs(18, 47); .ls(-2); .c(white); .wh(280, 47); .bg-c; .bg('@{tft-leaderboard}/title_frame_m.svg');}
      .inner-box { .wh(100%);
        &:before { .abs; .cnt; .lb(0, 0); .wh(0, 0); border-style: solid; border-width: 30px 0 0 30px; border-color: transparent transparent transparent #263889;}
        &:after { .abs; .cnt; .rb(0, 0); .wh(0, 0); border-style: solid; border-width: 0 0 30px 30px; border-color: transparent transparent #263889 transparent;}
        .detail { .fs(13, 17); .c(#cbd2f2); .rel; .wh(273, 30); .mh-c; .mb(12);
          .bullet1 { .abs; .z(1); .lt(0, 50%); .t-y(-21%); .c(#7f8bc3);}
          .bullet2 { .abs; .z(1); .rt(0, 50%); .t-y(-21%); .c(#7f8bc3);}
        }
      }
    }
  }
  .divider { .bg('@{tft-home}/div_m.svg'); .bg-c; .no-repeat; .wh(272, 8); .mh-c; .mt(24); .mb(58);}

  @media (@tl-up) {
    .contents-container { .max-w(780); .mh-c; .p(6);
      &:before {.lb(50%, 6);}
      .contents-holder { .p(46, 44, 58);
        .title { .t(-42); .wh(396, 53); .bg('@{tft-leaderboard}/title_frame_pc.svg'); .fs(22, 53);}
        .inner-box {
          .detail { .fs(16, 16); .wh(100%); .lh(30); .vam; .mb(18);
            > br { .hide;}
            .bullet1 { .l(45); .t-y(-41%);}
            .bullet2 { .r(45); .t-y(-41%);}
          }
        }
      }
    }
    .divider { .mt(48); .mb(88);}
  }
}

</style>
