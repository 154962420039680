<template>
  <div tft-rank-table :class="['table', {weekly}]">
    <table>
      <thead>
        <tr>
          <th v-for="(label, index) in labelList" :key="`${uid}-th-${index}`">{{ label }}</th>
        </tr>
        <tr class="margin">
          <th colspan="3" />
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, index) in info.data" :key="`${uid}-first-${index}`">
          <td :class="['rank', `rank-${row.rank}`]">
            <img v-if="row.rank <= 4"
              :src="`/img/pages/tftWeeklyCup/leaderboard/rank_${row.rank}.svg`" />
            <span v-else class="ranking">{{ row.rank }}위</span>
          </td>
          <td class="player">
            {{ row.player }}
          </td>
          <td v-if="row.weeklyPrice" class="price">{{ row.weeklyPrice }}만원</td>
          <td v-if="row.weeklyPoint" class="point">{{ row.weeklyPoint }}점</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'TftRankTable',
  props: {
    info: {
      type: Object,
      default: () => ({
        label: [],
        data: [],
      }),
    },
    weekly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    uid() {
      return this._uid;
    },
    labelList() {
      return this.info.label;
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[tft-rank-table] { .font-g; .tc;
  thead { .fs(11, 11px); .tc; .medium; .bgc(#2589ff); .c(white);
    > tr {
      > th { .fs(11, 12); .h(34); .p(0, 5); .-r(#0c1b60, 1px);
        &:nth-of-type(1) { .w(15%);}
        &:nth-of-type(2) { .w(60%);}
        &:nth-of-type(3) { .w(35%);}
      }
    }
    .margin {
      > th {.bgc(#0c1b60); .h(6); .-r;}
    }
  }
  tbody { .tc; .bold; .fs(14, 14px);
    > tr { .bgc(#0a58b4);
      &:nth-of-type(2n) { .bgc(#094d9e);}
    }
    td { .fs(13, 13); .bold; .h(56); .p(0, 5); .-r(#0c1b60, 1px); .-b(#0c1b60, 1px);
      &.player { .w(40%); .c(white); }
      &.price { .w(22%); .c(#80fff7); }
      &.point { .w(22%); .c(#c7ff35);}
    }
    .rank { .lh(20);
      > img { .wh(32); }
      .ranking { .fs(13); .c(#65a5f2);}
    }
  }
  &.weekly {
    thead {
      > tr {
        > th {
          &:nth-of-type(1) { .w(15%);}
          &:nth-of-type(2) { .w(45%);}
          &:nth-of-type(3) { .w(20%);}
          &:nth-of-type(4) { .w(20%);}
        }
      }
    }
  }

  @media (@tl-up) {
    thead { .fs(14, 14);
      > tr {
        > th { .fs(14, 15); .h(44); }
      }
    }
    tbody {
      td { .fs(20, 20);
        &.rank { .w(72);
          > img { .wh(38, 42); }
          .ranking { .fs(20); }
        }
        &.player { .w(333); .c(white); }
        &.price { .w(138); .c(#80fff7); }
        &.point { .w(138); .c(#c7ff35);}
      }
    }
  }
}
</style>
